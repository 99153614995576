import { Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import ChecklistIcon from '@mui/icons-material/Checklist';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

export interface HowItWorksProps {
  isMediumScreen: boolean;
  isMobile: boolean;
}

function HowItWorks(props: HowItWorksProps) {
  return (
    <Stack spacing={0} alignItems="center" sx={{ marginTop: 3, paddingLeft: 11, paddingRight: 11 }}>
      <Typography sx={{ fontSize: "3.6rem", fontWeight: "500", marginBottom: 2, textAlign: props.isMediumScreen ? "center" : null }}>
        How We work for You
      </Typography>
      <Grid container spacing={6}>
        {[
          {
            icon: <ChecklistIcon sx={{ fontSize: "3rem", color: "#f8b700" }} />,
            title: "Fill out the form on our website",
            description:
              "Specify the service you want, your location, and any other info to help us know more about what you're looking for.",
          },
          {
            icon: <CurrencyExchangeIcon sx={{ fontSize: "3rem", color: "#f8b700" }} />,
            title: "We negotiate with agents to find a match",
            description:
              "We will provide you with the best combination of price and agent fit.",
          },
          {
            icon: <RingVolumeIcon sx={{ fontSize: "3rem", color: "#f8b700" }} />,
            title: "You get a free consultation with that agent",
            description:
              "Chat with the agent to see if you agree with the fit. This is completely free!",
          },
          {
            icon: <SentimentVerySatisfiedIcon sx={{ fontSize: "3rem", color: "#f8b700" }} />,
            title: "Keep getting new matches until you are satisfied",
            description: "Once you choose the perfect agent for you, that's it!",
          },
        ].map((item, index) => (
          <Grid item xs={props.isMobile ? 12 : props.isMediumScreen ? 6 : 3} key={index} sx={{ marginBottom: props.isMobile ? 2 : props.isMediumScreen ? 3 : 5 }}>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%", // Ensure the Paper takes up the full height of the Grid item
              }}
            >
              <Stack spacing={1} sx={{ flexGrow: 1 }}>
                <Typography sx={{ textAlign: "center" }}>{item.icon}</Typography>
                <Typography sx={{ fontSize: "1.3rem", fontWeight: "300", textAlign: "center" }}>
                  {item.title}
                </Typography>
              </Stack>
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  color: "gray",
                  textAlign: "center",
                  marginTop: -2,
                }}
              >
                {item.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}

export default React.memo(HowItWorks);
