import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./screens/Landing/Landing";
import CircularProgress from "@mui/material/CircularProgress";
import { setupFirebase } from "./firebase";

const Profile = lazy(
  () => import(/* webpackPrefetch: true */ "./screens/Profile/Profile")
);

const Dashboard = lazy(
  () => import(/* webpackPrefetch: true */ "./screens/Dashboard/Dashboard")
);

const PageNotFound = lazy(() => import("./screens/404/PageNotFound"));

const Terms = lazy(() => import("./screens/Terms/Terms"));

const Privacy = lazy(() => import("./screens/Privacy/Privacy"));

const About = lazy(() => import("./screens/About/About"));

const Contact = lazy(() => import("./screens/Contact/Contact"));

const Promo = lazy(() => import("./screens/Promos/Promo"));

function App() {
  setupFirebase();

  return (
    <Router>
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              width: "100vw",
              position: "fixed",
              top: 0,
              left: 0,
              backgroundColor: "#f0f0f0",
              zIndex: 1000,
            }}
          >
            <CircularProgress size={60} sx={{ color: "#0039CB" }} />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/profile/:agent" element={<Profile />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/promo" element={<Promo />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
