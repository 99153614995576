import { FirebaseApp, initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, Auth } from "firebase/auth";
import { getPerformance } from "firebase/performance";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

let firebaseApp: FirebaseApp;

export const setupFirebase = () => {
  try {
    firebaseApp = initializeApp({
      apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    });
    initializeAppCheck(firebaseApp, {
      provider: new ReCaptchaV3Provider(
        "6LdUrjQqAAAAABWPVx_zvPYwkXDVHtTv-S76PTB9"
      ),
      isTokenAutoRefreshEnabled: true,
    });
    // Initialize Performance Monitoring and get a reference to the service
    getPerformance(firebaseApp);
    getAnalytics(firebaseApp);
  } catch (error) {
    console.error({ error });
  }
};

let auth: Auth;
let firestore: ReturnType<typeof getFirestore>;
let storage: ReturnType<typeof getStorage>;

export const useAuth = (): Auth => {
  if (!auth) {
    auth = getAuth(firebaseApp);
  }
  return auth;
};

export const useFirestore = () => {
  if (!firestore) {
    firestore = getFirestore();
  }
  return firestore;
};

export const useStorage = () => {
  if (!storage) {
    storage = getStorage();
  }
  return storage;
};
