import { Box, Grid, Stack, styled, Typography } from "@mui/material";
import React from "react";
// import Photo1 from "./LandingPhoto1.jpg";
import Photo2 from "./LandingPhoto2.jpg";
import Photo3 from "./LandingPhoto3.jpg";
import Photo4 from "./LandingPhoto4.jpg";
// import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MultipleStopOutlinedIcon from '@mui/icons-material/MultipleStopOutlined';

export interface AdditionalInfoProps {
  isMediumScreen: boolean;
  isMobile: boolean;
}

const LandingImageLarge = styled("img")({
  width: "550px",
  height: "auto",
  borderRadius: "8px",
});

const LandingImageMedium = styled("img")({
  width: "400px",
  height: "auto",
  borderRadius: "8px",
});

const LandingImageSmall = styled("img")({
  width: "300px",
  height: "auto",
  borderRadius: "8px",
});

function AddtionalInfo(props: AdditionalInfoProps) {
  return (
    <>
      {props.isMobile ? (
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          {/* <Grid item xs={12} sx={{ marginTop: 12, display: "flex", justifyContent: "center" }}>
            <LandingImageSmall src={Photo1} alt="Low prices" />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 10, maxWidth: "550px" }}>
            <Stack spacing={1} sx={{  pl: 5, pr: 5 }}>
              <Typography sx={{ fontSize: "3.3rem", fontWeight: "500", textAlign: "center" }}>
                Low Prices <TrendingDownIcon sx={{ fontSize: "4.5rem", color: "#f8b700", marginBottom: -2 }} />
              </Typography>
              <Typography sx={{ fontSize: "1.5rem", fontWeight: "300", textAlign: "center" }}>
                Don't pay more than you have to for a real estate agent. Let us negotatiate the price for you!
              </Typography>
              <Typography sx={{ fontSize: "1rem", fontWeight: "300", color: "gray", textAlign: "center" }}>
                We guarantee that our algorithm will find you the lowest possible price.
              </Typography>
            </Stack>
          </Grid> */}
          <Box sx={{ backgroundColor: "#eeeeee", width: "100%", paddingBottom: 8 }}>
            <Grid container spacing={3} justifyContent="center" alignItems="center">
              <Grid item xs={12} sx={{ marginTop: 8, display: "flex", justifyContent: "center" }}>
                <LandingImageSmall src={Photo2} alt="Flexible Options" />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: 2 }}>
                <Stack spacing={1} sx={{  pl: 5, pr: 5 }}>
                  <Typography sx={{ fontSize: "3.3rem", fontWeight: "500", textAlign: "center" }}>
                    Flexible Options <MultipleStopOutlinedIcon sx={{ fontSize: "4.5rem", color: "#f8b700", marginBottom: -2 }} />
                  </Typography>
                  <Typography sx={{ fontSize: "1.5rem", fontWeight: "300", textAlign: "center" }}>
                    Choose a normal full service agent, or an agent who can help you only with specific tasks!
                  </Typography>
                  <Typography sx={{ fontSize: "1rem", fontWeight: "300", color: "gray", textAlign: "center" }}>
                    If you don't need help for every step of the process, we don't want you to pay for it.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Grid item xs={12} sx={{ marginTop: 5, display: "flex", justifyContent: "center" }}>
            <LandingImageSmall src={Photo3} alt="Data driven agent matching" />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2, maxWidth: "550px", paddingBottom: 8 }}>
            <Stack spacing={1} sx={{  pl: 5, pr: 5 }}>
              <Typography sx={{ fontSize: "3.3rem", fontWeight: "500", textAlign: "center" }}>
                Data Driven
              </Typography>
              <Typography sx={{ fontSize: "3.3rem", fontWeight: "500", textAlign: "center" }}>
                Matching <TroubleshootIcon sx={{ fontSize: "4.5rem", color: "#f8b700", marginBottom: -2 }} />
              </Typography>
              <Typography sx={{ fontSize: "1.5rem", fontWeight: "300", textAlign: "center" }}>
                We match you with an agent who is best suited for your needs.
              </Typography>
              <Typography sx={{ fontSize: "1rem", fontWeight: "300", color: "gray", textAlign: "center" }}>
                Don't worry about not finding the right fit. We've got you covered.
              </Typography>
            </Stack>
          </Grid>
          <Box sx={{ backgroundColor: "#eeeeee", width: "100%", paddingBottom: 8 }}>
            <Grid container spacing={3} justifyContent="center" alignItems="center">
              <Grid item xs={12} sx={{ marginTop: 8, display: "flex", justifyContent: "center" }}>
                <LandingImageSmall src={Photo4} alt="No commitment" />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: 2 }}>
                <Stack spacing={1} sx={{  pl: 5, pr: 5 }}>
                  <Typography sx={{ fontSize: "3.3rem", fontWeight: "500", textAlign: "center" }}>
                    No Commitment <CancelOutlinedIcon sx={{ fontSize: "4.5rem", color: "#f8b700", marginBottom: -2 }} />
                  </Typography>
                  <Typography sx={{ fontSize: "1.5rem", fontWeight: "300", textAlign: "center" }}>
                    There's no strings attached. You don't have to pay us anything.
                  </Typography>
                  <Typography sx={{ fontSize: "1rem", fontWeight: "300", color: "gray", textAlign: "center" }}>
                    If you don't like the agent that you're matched with, we'll match you with a new one.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      ) : (
        props.isMediumScreen ? (
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            {/* <Grid item xs={12} sx={{ marginTop: 12, display: "flex", justifyContent: "center" }}>
              <LandingImageMedium src={Photo1} alt="Low prices" />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 10, maxWidth: "550px" }}>
              <Stack spacing={1} sx={{  pl: 11, pr: 11 }}>
                <Typography sx={{ fontSize: "3.6rem", fontWeight: "500", textAlign: "center" }}>
                  Low Prices <TrendingDownIcon sx={{ fontSize: "5rem", color: "#f8b700", marginBottom: -2 }} />
                </Typography>
                <Typography sx={{ fontSize: "1.8rem", fontWeight: "300", textAlign: "center" }}>
                  Don't pay more than you have to for a real estate agent. Let us negotatiate the price for you!
                </Typography>
                <Typography sx={{ fontSize: "1.2rem", fontWeight: "300", color: "gray", textAlign: "center" }}>
                  We guarantee that our algorithm will find you the lowest possible price.
                </Typography>
              </Stack>
            </Grid> */}
            <Box sx={{ backgroundColor: "#eeeeee", width: "100%", paddingBottom: 10 }}>
              <Grid container spacing={3} justifyContent="center" alignItems="center">
                <Grid item xs={12} sx={{ marginTop: 8, display: "flex", justifyContent: "center" }}>
                  <LandingImageMedium src={Photo2} alt="Flexible Options" />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <Stack spacing={1} sx={{  pl: 11, pr: 11 }}>
                    <Typography sx={{ fontSize: "3.6rem", fontWeight: "500", textAlign: "center" }}>
                      Flexible Options <MultipleStopOutlinedIcon sx={{ fontSize: "5rem", color: "#f8b700", marginBottom: -2 }} />
                    </Typography>
                    <Typography sx={{ fontSize: "1.8rem", fontWeight: "300", textAlign: "center" }}>
                      Choose a normal full service agent, or an agent who can help you only with specific tasks!
                    </Typography>
                    <Typography sx={{ fontSize: "1.2rem", fontWeight: "300", color: "gray", textAlign: "center" }}>
                      If you don't need help for every step of the process, we don't want you to pay for it.
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Grid item xs={12} sx={{ marginTop: 5, display: "flex", justifyContent: "center" }}>
              <LandingImageMedium src={Photo3} alt="Data driven agent matching" />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 2, maxWidth: "550px", paddingBottom: 10 }}>
              <Stack spacing={1} sx={{  pl: 11, pr: 11 }}>
                <Typography sx={{ fontSize: "3.6rem", fontWeight: "500", textAlign: "center" }}>
                  Data Driven
                </Typography>
                <Typography sx={{ fontSize: "3.6rem", fontWeight: "500", textAlign: "center" }}>
                  Matching <TroubleshootIcon sx={{ fontSize: "5rem", color: "#f8b700", marginBottom: -2 }} />
                </Typography>
                <Typography sx={{ fontSize: "1.8rem", fontWeight: "300", textAlign: "center" }}>
                  We match you with an agent who is best suited for your needs.
                </Typography>
                <Typography sx={{ fontSize: "1.2rem", fontWeight: "300", color: "gray", textAlign: "center" }}>
                  Don't worry about not finding the right fit. We've got you covered.
                </Typography>
              </Stack>
            </Grid>
            <Box sx={{ backgroundColor: "#eeeeee", width: "100%", paddingBottom: 10 }}>
              <Grid container spacing={3} justifyContent="center" alignItems="center">
                <Grid item xs={12} sx={{ marginTop: 8, display: "flex", justifyContent: "center" }}>
                  <LandingImageMedium src={Photo4} alt="No commitment" />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <Stack spacing={1} sx={{  pl: 11, pr: 11 }}>
                    <Typography sx={{ fontSize: "3.6rem", fontWeight: "500", textAlign: "center" }}>
                      No Commitment <CancelOutlinedIcon sx={{ fontSize: "5rem", color: "#f8b700", marginBottom: -2 }} />
                    </Typography>
                    <Typography sx={{ fontSize: "1.8rem", fontWeight: "300", textAlign: "center" }}>
                      There's no strings attached. You don't have to pay us anything.
                    </Typography>
                    <Typography sx={{ fontSize: "1.2rem", fontWeight: "300", color: "gray", textAlign: "center" }}>
                      If you don't like the agent that you're matched with, we'll match you with a new one.
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ) : (
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            {/* <Grid container spacing={3} justifyContent="center" alignItems="center">
              <Grid item xs={6} sx={{ marginTop: 10, maxWidth: "550px" }}>
                <Stack spacing={1} sx={{  ml: 11 }}>
                  <Typography sx={{ fontSize: "3.6rem", fontWeight: "500" }}>
                    Low Prices <TrendingDownIcon sx={{ fontSize: "5rem", color: "#f8b700", marginBottom: -2 }} />
                  </Typography>
                  <Typography sx={{ fontSize: "1.8rem", fontWeight: "300" }}>
                    Don't pay more than you have to for a real estate agent. Let us negotatiate the price for you!
                  </Typography>
                  <Typography sx={{ fontSize: "1.2rem", fontWeight: "300", color: "gray" }}>
                    We guarantee that our algorithm will find you the lowest possible price.
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} sx={{ marginTop: 12, display: "flex", justifyContent: "center" }}>
                <LandingImageLarge src={Photo1} alt="Low prices" />
              </Grid>
            </Grid> */}
            <Box sx={{ backgroundColor: "#eeeeee", width: "100%", paddingBottom: 10 }}>
              <Grid container spacing={3} justifyContent="center" alignItems="center">
                <Grid item xs={6} sx={{ marginTop: 12, display: "flex", justifyContent: "center" }}>
                  <LandingImageLarge src={Photo2} alt="Flexible Options" />
                </Grid>
                <Grid item xs={6} sx={{ marginTop: 12 }}>
                  <Stack spacing={1} sx={{ ml: 3, paddingRight: 20 }}>
                    <Typography sx={{ fontSize: "3.6rem", fontWeight: "500" }}>
                      Flexible Options
                      <MultipleStopOutlinedIcon
                        sx={{ fontSize: "5rem", color: "#f8b700", marginBottom: -2 }}
                      />
                    </Typography>
                    <Typography sx={{ fontSize: "1.8rem", fontWeight: "300" }}>
                      Choose a normal full service agent, or an agent who can help you only with specific tasks!
                    </Typography>
                    <Typography sx={{ fontSize: "1.2rem", fontWeight: "300", color: "gray" }}>
                      If you don't need help for every step of the process, we don't want you to pay for it.
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{ marginBottom: 10 }}>
              <Grid item xs={6} sx={{ marginTop: 12, maxWidth: "550px" }}>
                <Stack spacing={1} sx={{ ml: 11 }}>
                  <Typography sx={{ fontSize: "3.6rem", fontWeight: "500" }}>
                    Data Driven
                  </Typography>
                  <Typography sx={{ fontSize: "3.6rem", fontWeight: "500" }}>
                    Matching
                    <TroubleshootIcon sx={{ fontSize: "5rem", color: "#f8b700", marginBottom: -2 }} />
                  </Typography>
                  <Typography sx={{ fontSize: "1.8rem", fontWeight: "300" }}>
                    We match you with an agent who is best suited for your needs.
                  </Typography>
                  <Typography sx={{ fontSize: "1.2rem", fontWeight: "300", color: "gray" }}>
                    Don't worry about not finding the right fit. We've got you covered.
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} sx={{ marginTop: 12, display: "flex", justifyContent: "center" }}>
                <LandingImageLarge src={Photo3} alt="Data driven agent matching" />
              </Grid>
            </Grid>
            <Box sx={{ backgroundColor: "#eeeeee", width: "100%", paddingBottom: 10 }}>
              <Grid container spacing={3} justifyContent="center" alignItems="center">
                <Grid item xs={6} sx={{ marginTop: 12, display: "flex", justifyContent: "center" }}>
                  <LandingImageLarge src={Photo4} alt="No commitment" />
                </Grid>
                <Grid item xs={6} sx={{ marginTop: 12 }}>
                  <Stack spacing={1} sx={{ ml: 3, paddingRight: 20 }}>
                    <Typography sx={{ fontSize: "3.6rem", fontWeight: "500" }}>
                      No Commitment
                      <CancelOutlinedIcon sx={{ fontSize: "5rem", color: "#f8b700", marginBottom: -2 }} />
                    </Typography>
                    <Typography sx={{ fontSize: "1.8rem", fontWeight: "300" }}>
                      There's no strings attached. You don't have to pay us anything.
                    </Typography>
                    <Typography sx={{ fontSize: "1.2rem", fontWeight: "300", color: "gray" }}>
                      If you don't like the agent that you're matched with, we'll match you with a new one.
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Grid>        
        )
      )}
    </>
  );
}

export default React.memo(AddtionalInfo);
