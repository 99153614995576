import { Alert, Snackbar } from "@mui/material";
import React from "react";

export interface SnackbarProps {
  snackbarOpen: boolean;
  handleSnackbarClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
  message: string;
}

function LoginSnackbar(props: SnackbarProps) {
  return (
    <Snackbar
        open={props.snackbarOpen}
        autoHideDuration={3000}
        onClose={props.handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={props.handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {props.message}
        </Alert>
      </Snackbar>
  );
}

export default React.memo(LoginSnackbar);
