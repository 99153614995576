import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/system";
import WhiteLogo from "./WhiteLogo.png";
import { Link } from "react-router-dom";
import { useAuth } from "../../firebase";
import { onAuthStateChanged, User, signOut } from "firebase/auth";
import "./LandingNavbar.css";
import { useNavigate } from "react-router-dom";

const Root = styled("div")({
  flexGrow: 1,
});

const MenuButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  fontSize: "3rem",
}));

const Logo = styled("div")({
  display: "flex",
  alignItems: "center",
});

const LogoImage = styled("img")({
  width: "200px",
  height: "auto",
  marginLeft: "-50px",
  marginTop: "20px",
});

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  height: theme.spacing(16),
}));

const CustomAppBar = styled(AppBar)({
  backgroundColor: "transparent",
  boxShadow: "none",
});

const CustomLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:visited {
    color: inherit;
  }
`;

const RightSide = styled("div")({
  display: "flex",
  alignItems: "center",
});

interface LandingProps {
  setSuccessfulSignOut: (successfulSignOut: boolean) => void;
  disablePromoLink: boolean;
}

const LandingNavbar: React.FC<LandingProps> = ({ setSuccessfulSignOut, disablePromoLink }) => {
  const auth = useAuth();
  const [authUser, setAuthUser] = useState<User | null>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const authWorkflow = () => {
    navigate("/dashboard");
    handleMenuClose();
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setAuthUser(null); // Optionally reset authUser state
        setSuccessfulSignOut(true);
        handleMenuClose();
      })
      .catch((error) => {
        console.error("Sign out error:", error);
      });
  };

  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });
    return () => {
      listen();
    };
  }, [auth]);

  return (
    <Root>
      <CustomAppBar position="static">
        <CustomToolbar>
          <CustomLink to="/">
            <Logo>
              <LogoImage src={WhiteLogo} alt="white-logo" />
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  marginLeft: "-70px",
                  marginTop: "8px",
                }}
              >
                Locagent
              </Typography>
            </Logo>
          </CustomLink>
          <RightSide>
            <MenuButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
            >
              <MenuIcon fontSize="inherit" />
            </MenuButton>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={isMenuOpen}
              onClose={handleMenuClose}
            >
              {authUser ? (
                <MenuItem component={Link} to="/dashboard">
                  Dashboard
                </MenuItem>
              ) : null}
              {authUser ? (
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              ) : (
                <MenuItem onClick={authWorkflow}>Log In/ Sign up</MenuItem>
              )}
              {!disablePromoLink && (
                <>
                  <Divider />
                  <MenuItem
                    component="a"
                    href="/promo"
                    rel="noopener noreferrer"
                  >
                    Get $500
                  </MenuItem>
                </>
              )}
              {/* <MenuItem component={Link} to="/about">
                About Us
              </MenuItem> */}
            </Menu>
          </RightSide>
        </CustomToolbar>
      </CustomAppBar>
    </Root>
  );
};

export default React.memo(LandingNavbar);
