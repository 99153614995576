import React from "react";
import { useNavigate } from "react-router-dom";
import "./Footer.css";
import { Box, Divider, Link, Typography } from "@mui/material";

function Footer() {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <>
      <Box sx={{ marginTop: 5, paddingLeft: 11, paddingRight: 11 }}>
        <Divider
          sx={{
            borderColor: 'black',  // Change the color of the divider
            borderWidth: '1px',  // Change the thickness
          }}
        />
      </Box>
      <div className="footer">
        <div className="footer-word-logo" onClick={handleLogoClick}>
          Locagent
        </div>
        <div className="footer-row">
          <Box
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gap={2}
            justifyContent="space-between"
            sx={{ maxWidth: 400, margin: "auto" }}
          >
            <Typography>
              <Link
                href="/contact"
                color="inherit"
                underline="none"
                sx={{
                  "&:hover": {
                    textDecoration: "underline",
                    color: "#1e90ff", // light blue color
                  },
                }}
              >
                Contact us
              </Link>
            </Typography>
            <Typography>
              <Link
                href="/privacy"
                color="inherit"
                underline="none"
                sx={{
                  "&:hover": {
                    textDecoration: "underline",
                    color: "#1e90ff",
                  },
                }}
              >
                Privacy
              </Link>
            </Typography>
            <Typography>
              <Link
                href="/terms"
                color="inherit"
                underline="none"
                sx={{
                  "&:hover": {
                    textDecoration: "underline",
                    color: "#1e90ff",
                  },
                }}
              >
                Terms
              </Link>
            </Typography>
            <Typography>
              <Link
                href="/about"
                color="inherit"
                underline="none"
                sx={{
                  "&:hover": {
                    textDecoration: "underline",
                    color: "#1e90ff",
                  },
                }}
              >
                About
              </Link>
            </Typography>
          </Box>
        </div>
      </div>
    </>
  );
}

export default React.memo(Footer);
