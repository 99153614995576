export const agentTasks = [
  "Paperwork",
  "Home search",
  "Schedule viewings",
  "Market analysis",
  "Negotiation",
  "Financing",
  "Inspections",
  "Coordinating closing",
  "Post sale support",
  "Fielding offers",
];

export const timelineOptions = [
  "Normal - around 6 months",
  "Quick - around 3 months",
  "Longer - around 9-12 months",
];

export const minVolumeOptions = [
  "No minimum",
  "$100k +",
  "$500k +",
  "$1 million +",
  "$5 million +",
  "$10 million +",
];

export const minTransactionsOptions = [
  "No minimum",
  "5 +",
  "10 +",
  "50 + ",
  "100 +",
  "500 +",
];

export const priceOptions = [
  "Choose approximate price",
  "$0 - $250k",
  "$250k - $500k",
  "$500k-$1 million",
  "$1 million - $2 million",
  "$2 million - $5 million",
  "$5 million +",
]