import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  OutlinedInput,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import "./Filters.css";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { 
  agentTasks,
  timelineOptions,
  minVolumeOptions,
  minTransactionsOptions,
  priceOptions,
} from "./FiltersDropdownOptions";
import { useNavigate } from "react-router-dom";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PaidIcon from '@mui/icons-material/Paid';
import AddTaskIcon from '@mui/icons-material/AddTask';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import HomeIcon from '@mui/icons-material/Home';

export interface FiltersProps {
  setIsNotValid: (isNotValid: boolean) => void;
  isMediumScreen: boolean;
  isMobile: boolean;
}

const Filters: React.FC<FiltersProps> = ({ setIsNotValid, isMediumScreen, isMobile }) => {
  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    service: false,
    role: false,
    location: false,
  });

  const [fullService, setFullService] = useState(true);
  const [partTime, setPartTime] = useState(false);
  const [specificTasks, setSpecificTasks] = useState<string[]>(["Paperwork"]);

  const [buyer, setBuyer] = useState(false);
  const [seller, setSeller] = useState(false);

  const [minTransactionVolume, setMinTransactionVolume] = useState("No minimum");
  const [minNumberTransactions, setMinNumberTransactions] = useState("No minimum");

  const [location, setLocation] = useState("");

  const [timeline, setTimeline] = useState<string>("Normal - around 6 months");

  const [priceRange, setPriceRange] = useState("Choose approximate price");

  const [investment, setInvestment] = useState(false);

  const CustomizedButton = styled(Button)({
    width: isMobile ? "80%" : "40%",
    height: 60,
    fontSize: 22,
    color: "white",
    backgroundColor: "#0039CB",
    "&:hover": {
      backgroundColor: "#335CA8",
    },
    borderRadius: 32,
    textTransform: "none",
  });

  const RequiredLabel = styled("span")({
    color: "red",
    fontSize: "1.5rem",
    marginTop: "8px !important",
    marginRight: "-18px !important",
  });

  const handleSubmit = async () => {
    let valid = true;
    const newErrors = {
      service: false,
      role: false,
      location: false,
    };
  
    if (!(fullService || partTime)) {
      newErrors.service = true;
      valid = false;
      setIsNotValid(true);
    }
  
    if (!(buyer || seller)) {
      newErrors.role = true;
      valid = false;
      setIsNotValid(true);
    }
  
    if (!location) {
      newErrors.location = true;
      valid = false;
      setIsNotValid(true);
    }
  
    setErrors(newErrors);
  
    if (valid) {
      // Set some kind of "pending submission" flag in localStorage or a global state
      localStorage.setItem('pendingSubmission', JSON.stringify({
        fullService,
        partTime,
        specificTasks,
        buyer,
        seller,
        minTransactionVolume,
        minNumberTransactions,
        location,
        timeline,
        priceRange,
        investment
      }));

      setFullService(true);
      setPartTime(false);
      setSpecificTasks(["Paperwork"]);
      setBuyer(false);
      setSeller(false);
      setMinTransactionVolume("No minimum");
      setMinNumberTransactions("No minimum");
      setLocation("");
      setTimeline("Normal - around 6 months");
      setPriceRange("Choose approximate price");
      setInvestment(false);
  
      // Redirect to the dashboard
      navigate("/dashboard");
    }
  };  

  return (
    <Box
      sx={{
        backgroundColor: "white",
        width: isMobile ? 350 : isMediumScreen ? 600 : 1050,
        height: isMediumScreen ? 700 : 350,
        borderRadius: "8px",
      }}
    >
      <Stack 
        spacing={3} 
        sx={{ 
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 1,
        }}
      >
        {isMediumScreen ? (
          <div>
            <Stack
              spacing={0}
              direction="column"
              sx={{
                backgroundColor: "#f1f1f1",
                margin: "-8px -16px 0 -16px", // Negative margin to counteract padding
                padding: "8px 16px 8px 16px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}
            >
              <div className="input-header" style={{ marginBottom: 3 }}>I want a:</div>
              <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
                <div className="input-header" style={{ marginLeft: -2 }}>Full service agent</div>
                {errors.service && (
                  <RequiredLabel>*</RequiredLabel>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fullService}
                      onChange={(e) => setFullService(e.target.checked)}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: errors.service ? "#f44336" : "#4A4A4A", // outer square default color
                        },
                        "&.Mui-checked .MuiSvgIcon-root": {
                          color: "#4A4A4A", // outer square color when checked
                        },
                        "&.Mui-checked": {
                          color: "#4A4A4A", // inner square color when checked
                        },
                      }}
                    />
                  }
                  label=""
                  className="input-header"
                />
              </Stack>
              <Stack spacing={1} direction="row" sx={{ alignItems: "center", marginTop: -1 }}>
                <div className="input-header" style={{ marginLeft: -2 }}>Part time agent for specific tasks</div>
                {errors.service && (
                  <RequiredLabel>*</RequiredLabel>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={partTime}
                      onChange={(e) => setPartTime(e.target.checked)}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: errors.service ? "#f44336" : "#4A4A4A", // outer square default color
                        },
                        "&.Mui-checked .MuiSvgIcon-root": {
                          color: "#4A4A4A", // outer square color when checked
                        },
                        "&.Mui-checked": {
                          color: "#4A4A4A", // inner square color when checked
                        },
                      }}
                    />
                  }
                  label=""
                  className="input-header"
                />
              </Stack>
              {partTime && (
                <Select
                  multiple
                  value={specificTasks}
                  onChange={(e) => setSpecificTasks(e.target.value as string[])}
                  IconComponent={AddTaskIcon}
                  renderValue={(selected) => selected.join(", ")} // Display selected options
                  sx={{
                    width: "220px",
                    height: 40,
                    borderRadius: 1,
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "3px",
                      borderColor: "lightgray",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "lightgray",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#0039CB",
                      borderWidth: "3px",
                    },
                    "& .MuiSelect-iconOpen": {
                      transform: "none",
                    },
                  }}
                >
                  {agentTasks.map((task, index) => (
                    <MenuItem
                    key={index}
                    value={task}
                  >
                    <ListItemText primary={task} />
                  </MenuItem>
                  ))}
                </Select>            
              )}
            </Stack>
          </div>
        ) : (
          <div>
            <Stack
              spacing={1}
              direction="row"
              sx={{
                alignItems: "center",
                backgroundColor: "#f1f1f1",
                margin: "-8px -16px 0 -16px", // Negative margin to counteract padding
                padding: "8px 16px 8px 16px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}
            >
              <div className="input-header" style={{ marginBottom: 3 }}>I want a:</div>
              {errors.service && (
                <RequiredLabel>*</RequiredLabel>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={fullService}
                    onChange={(e) => setFullService(e.target.checked)}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: errors.service ? "#f44336" : "#4A4A4A", // outer square default color
                      },
                      "&.Mui-checked .MuiSvgIcon-root": {
                        color: "#4A4A4A", // outer square color when checked
                      },
                      "&.Mui-checked": {
                        color: "#4A4A4A", // inner square color when checked
                      },
                    }}
                  />
                }
                label=""
                className="input-header"
              />
              <div className="input-header" style={{ marginLeft: -2 }}>Full service agent</div>
              {errors.service && (
                <RequiredLabel>*</RequiredLabel>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={partTime}
                    onChange={(e) => setPartTime(e.target.checked)}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: errors.service ? "#f44336" : "#4A4A4A", // outer square default color
                      },
                      "&.Mui-checked .MuiSvgIcon-root": {
                        color: "#4A4A4A", // outer square color when checked
                      },
                      "&.Mui-checked": {
                        color: "#4A4A4A", // inner square color when checked
                      },
                    }}
                  />
                }
                label=""
                className="input-header"
              />
              <div className="input-header" style={{ marginLeft: -2 }}>Part time agent for specific tasks</div>
              {partTime && (
                <Select
                  multiple
                  value={specificTasks}
                  onChange={(e) => setSpecificTasks(e.target.value as string[])}
                  IconComponent={AddTaskIcon}
                  renderValue={(selected) => selected.join(", ")} // Display selected options
                  sx={{
                    width: "220px",
                    height: 40,
                    borderRadius: 1,
                    backgroundColor: "white",
                    marginTop: 2,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "3px",
                      borderColor: "lightgray",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "lightgray",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#0039CB",
                      borderWidth: "3px",
                    },
                    "& .MuiSelect-iconOpen": {
                      transform: "none",
                    },
                  }}
                >
                  {agentTasks.map((task, index) => (
                    <MenuItem
                    key={index}
                    value={task}
                  >
                    <ListItemText primary={task} />
                  </MenuItem>
                  ))}
                </Select>            
              )}
            </Stack>
          </div>
        )}
        <Grid container>
          <Grid item xs={isMediumScreen ? 12 : 3} sx={{ marginTop: isMediumScreen ? -2 : 3 }}>
            <Stack
              spacing={1}
              direction="row"
              sx={{
                alignItems: "center",
                paddingRight: isMediumScreen ? 0 : 1,
              }}
            >
              <div className="input-header" style={{ marginBottom: 3 }}>I am a:</div>
              {errors.role && (
                <RequiredLabel>*</RequiredLabel>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={buyer}
                    onChange={(e) => setBuyer(e.target.checked)}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: errors.role ? "#f44336" : "#4A4A4A", // outer square default color
                      },
                      "&.Mui-checked .MuiSvgIcon-root": {
                        color: "#4A4A4A", // outer square color when checked
                      },
                      "&.Mui-checked": {
                        color: "#4A4A4A", // inner square color when checked
                      },
                    }}
                  />
                }
                label=""
                className="input-header"
              />
              <div className="input-header" style={{ marginLeft: -2 }}>Buyer</div>
              {errors.role && (
                <RequiredLabel>*</RequiredLabel>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={seller}
                    onChange={(e) => setSeller(e.target.checked)}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: errors.role ? "#f44336" : "#4A4A4A", // outer square default color
                      },
                      "&.Mui-checked .MuiSvgIcon-root": {
                        color: "#4A4A4A", // outer square color when checked
                      },
                      "&.Mui-checked": {
                        color: "#4A4A4A", // inner square color when checked
                      },
                    }}
                  />
                }
                label=""
                className="input-header"
              />
              <div className="input-header" style={{ marginLeft: -2 }}>Seller</div>
            </Stack>
          </Grid>
          <Grid item xs={isMediumScreen ? 12 : 3}>
            <Stack sx={{ paddingLeft: isMediumScreen ? 0 : 1, paddingRight: isMediumScreen ? 0 : 1}}>
              <div className="input-header">Agent min. transaction volume</div>
              <Select
                value={minTransactionVolume}
                onChange={(e) => setMinTransactionVolume(e.target.value)}
                displayEmpty
                IconComponent={LocalAtmIcon}
                sx={{
                  width: "100%",
                  height: 40,
                  borderRadius: 1,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "lightgray",
                    borderWidth: "3px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "lightgray",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#0039CB",
                    borderWidth: "3px",
                  },
                  "& .MuiSelect-iconOpen": {
                    transform: "none",
                  },
                }}
              >
                {minVolumeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={isMediumScreen ? 12 : 3}>
            <Stack sx={{ paddingLeft: isMediumScreen ? 0 : 1, paddingRight: isMediumScreen ? 0 : 1, marginTop: isMediumScreen ? 1 : 0 }}>
              <div className="input-header">Agent min. # of transactions</div>
              <Select
                value={minNumberTransactions}
                onChange={(e) => setMinNumberTransactions(e.target.value)}
                displayEmpty
                IconComponent={HomeIcon}
                sx={{
                  width: "100%",
                  height: 40,
                  borderRadius: 1,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "lightgray",
                    borderWidth: "3px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "lightgray",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#0039CB",
                    borderWidth: "3px",
                  },
                  "& .MuiSelect-iconOpen": {
                    transform: "none",
                  },
                }}
              >
                {minTransactionsOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={isMediumScreen ? 12 : 3}>
            <Stack sx={{ paddingLeft: isMediumScreen ? 0 : 1, marginTop: isMediumScreen ? 1 : 0 }}>
              <div className="input-header">Timeline</div>
              <Select
                value={timeline}
                onChange={(e) => setTimeline(e.target.value)}
                displayEmpty
                IconComponent={CalendarMonthIcon}
                sx={{
                  width: "100%",
                  height: 40,
                  borderRadius: 1,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "lightgray",
                    borderWidth: "3px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "lightgray",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#0039CB",
                    borderWidth: "3px",
                  },
                  "& .MuiSelect-iconOpen": {
                    transform: "none",
                  },
                }}
              >
                {timelineOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={isMediumScreen ? 12 : 5} sx={{ paddingRight: isMediumScreen ? 0 : 1, marginTop: isMediumScreen ? -2 : 0 }}>
            <Stack>
              <div className="input-header">Location</div>
              <OutlinedInput
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                placeholder="Enter County, Zip, City, etc..."
                error={!!errors.location}  // This prop will automatically apply error styles
                endAdornment={
                  errors.location && (
                    <InputAdornment position="end">
                      <ErrorOutlineIcon color="error" />
                    </InputAdornment>
                  )
                }
                sx={{
                  width: "100%",
                  height: 40,
                  borderRadius: 1,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: errors.location ? "#f44336" : "lightgray",
                    borderWidth: "3px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "lightgray",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#0039CB",
                    borderWidth: "3px",
                  },
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={isMediumScreen ? 12 : 4} sx={{ paddingRight: isMediumScreen ? 0 : 1, paddingLeft: isMediumScreen ? 0 : 1, marginTop: isMediumScreen ? 1 : 0 }}>
            <Stack>
              <div className="input-header">Price</div>
              <Select
                value={priceRange}
                onChange={(e) => setPriceRange(e.target.value)}
                displayEmpty
                IconComponent={PaidIcon}
                sx={{
                  width: "100%",
                  height: 40,
                  borderRadius: 1,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "lightgray",
                    borderWidth: "3px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "lightgray",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#0039CB",
                    borderWidth: "3px",
                  },
                  "& .MuiSelect-iconOpen": {
                    transform: "none",
                  },
                }}
              >
                {priceOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={isMediumScreen ? 12 : 3} sx={{ marginTop: isMediumScreen ? 1 : 3 }}>
            {isMediumScreen ? (
              <Stack
                spacing={1}
                direction="row"
                sx={{
                  alignItems: "center",
                }}
              >
                <div className="input-header">Investment home?</div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={investment}
                      onChange={(e) => setInvestment(e.target.checked)}
                      sx={{
                        color: "#4A4A4A",
                        "&.Mui-checked": {
                          color: "#4A4A4A",
                        },
                      }}
                    />
                  }
                  label=""
                  className="input-header"
                />
              </Stack>
            ) : (
              <Stack
                spacing={1}
                direction="row"
                sx={{
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={investment}
                      onChange={(e) => setInvestment(e.target.checked)}
                      sx={{
                        color: "#4A4A4A",
                        "&.Mui-checked": {
                          color: "#4A4A4A",
                        },
                      }}
                    />
                  }
                  label=""
                  className="input-header"
                />
                <div className="input-header">Investment home?</div>
              </Stack>
            )}
          </Grid>
        </Grid>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CustomizedButton onClick={handleSubmit}>Find an Agent</CustomizedButton>
        </Box>
      </Stack>
    </Box>
  );
};

export default React.memo(Filters);
