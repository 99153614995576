import {
  Grid,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LandingPageImage from "./LandingPage.jpg";
import LandingNavbar from "components/LandingComponents/LandingNavbar";
import LoginSnackbar from "components/Auth/LoginSnackbar";
import "./Landing.css";
import Filters from "components/LandingComponents/Filters";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Footer from "shared/Footer/Footer";
import Additionalnfo from "components/LandingComponents/Additionalnfo";
import HowItWorks from "components/LandingComponents/HowItWorks";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Landing() {
  const mediaTheme = useTheme();
  const isMobile = useMediaQuery(mediaTheme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(mediaTheme.breakpoints.down("md"));
  const [successfulLogIn, setSuccessfulLogIn] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [successfulSignOut, setSuccessfulSignOut] = useState<boolean>(false);
  const [snackbarOpenSignOut, setSnackbarOpenSignOut] =
    useState<boolean>(false);
  const [isNotValid, setIsNotValid] = useState<boolean>(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState<boolean>(false);

  useEffect(() => {
    if (successfulLogIn) {
      setSnackbarOpen(true);
    }
    if (successfulSignOut) {
      setSnackbarOpenSignOut(true);
    }
  }, [successfulLogIn, successfulSignOut]);

  useEffect(() => {
    if (isNotValid) {
      setErrorSnackbarOpen(true);
    }
  }, [isNotValid]);

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setSuccessfulLogIn(false);
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSnackbarCloseSignOut = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setSuccessfulSignOut(false);
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpenSignOut(false);
  };

  const handleErrorSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setIsNotValid(false);
    if (reason === "clickaway") {
      return;
    }
    setErrorSnackbarOpen(false);
  };

  return (
    <>
      <div
        className="landing"
        style={{
          backgroundImage: `url(${LandingPageImage})`,
        }}
      >
        <Grid container direction="column" sx={{ marginBottom: 10 }}>
          <Grid item xs={1} alignItems="stretch">
            <LandingNavbar setSuccessfulSignOut={setSuccessfulSignOut} disablePromoLink={false} />
          </Grid>
          <Grid
            item
            xs={1}
            alignSelf="center"
            sx={{ marginTop: isMobile ? null : "15vh" }}
          >
            <Stack spacing={isMobile ? 1 : 5} alignItems="center">
              {isMobile ? (
                <>
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      textShadow: "3px 3px 6px rgba(0, 0, 0, 0.8)",
                    }}
                  >
                    Get the best
                  </Typography>
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      textShadow: "3px 3px 6px rgba(0, 0, 0, 0.8)",
                    }}
                  >
                    real estate agent
                  </Typography>
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      textShadow: "3px 3px 6px rgba(0, 0, 0, 0.8)",
                    }}
                  >
                    for you
                  </Typography>
                </>
              ) : (
                <Typography
                  variant="h4"
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    textShadow: "3px 3px 6px rgba(0, 0, 0, 0.8)",
                  }}
                >
                  Get the best real estate agent for you
                </Typography>
              )}
              <Filters
                setIsNotValid={setIsNotValid}
                isMediumScreen={isMediumScreen}
                isMobile={isMobile}
              />
            </Stack>
          </Grid>
        </Grid>
      </div>
      <Additionalnfo isMobile={isMobile} isMediumScreen={isMediumScreen} />
      <HowItWorks isMobile={isMobile} isMediumScreen={isMediumScreen} />
      <Footer />
      <LoginSnackbar
        snackbarOpen={snackbarOpen}
        handleSnackbarClose={handleSnackbarClose}
        message="Successfully logged in!"
      />
      <LoginSnackbar
        snackbarOpen={snackbarOpenSignOut}
        handleSnackbarClose={handleSnackbarCloseSignOut}
        message="Successfully signed out!"
      />
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={2000}
        onClose={handleErrorSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleErrorSnackbarClose} severity="error">
          Please fill out the required fields
        </Alert>
      </Snackbar>
    </>
  );
}

export default React.memo(Landing);
